<template>
    <div>
        <div class="header">
            <h1 class="title">个人设置</h1>
        </div>
        <div class="main-content tab-content">
            <ul class="settings-tab-wrap action-bar" @click="handleTarget">
                <li class="tab target" data-name="account">账号设置</li>
                <li class="tab" data-name="myCompany">我的企业</li>
            </ul>
            <component :is="currentTab"></component>
        </div>
    </div>  
</template>
<script>
    import myCompany from './company/index'
    import account from './account/index'

    export default {
        name: "",
        mixins: [],
        components: {
            myCompany,
            account
        },
        props: [],
        data () {
            return {
                currentTab: 'account'
            }
        },
        computed: {},
        methods: {
            handleTarget(e) {
                let eles = e.currentTarget.children,
                    len = eles.length;

                for (var i = 0; i < len; i++) {
                    eles[i].setAttribute('class', 'tab')
                }

                e.target.setAttribute('class', 'tab target')
                this.currentTab = e.target.dataset.name
            }
        },
        created() {}
    }
</script>
<style scoped  lang="less">
@import '~less/color';
.target {
    color: @primary-color;
}
 
.settings-tab-wrap {
    display: inline-block;
    margin: 0 2px 0 -10px;
    font-size: 16px;
    .tab {
        cursor: pointer;
        position: relative;
        display: inline-block;
        padding: 0 20px 0 10px;
        &::after {
            position: absolute;
            top: 2px;
            bottom: 2px;
            right: 0;
            width: 1px;
            background: #e8eaec;
            content: '\200B';
        }
        &:last-child::after {
            display: none;
        }
    }
}
.tab-content {
    padding: 22px 29px 160px;
}
</style>