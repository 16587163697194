<template>
    <div id="account">
        <dl class="user-header">
            <dt class="avatar-wrap pointer">
                <Upload
                    class="upload"
                    action=""
                    :accept="formats"
                    :before-upload="handleBeforeUpload"
                >
                    <img v-if="avatar_uri" class="image-center avatar" :src="`/cym/${avatar_uri}`">
                    <div>上传</div>
                </Upload>
            </dt>
            <dd class="info-wrap">
                <div class="name-wrap pointer" @click="showElement($event, undefined, 22)">
                    <div class="name line-overflow">
                        {{ user_name }}
                    </div>
                    <Icon class="icon" type="md-create" size="18" />
                </div>
                <div v-if="!is_org" class="upgrade-info pointer" @click="handleUpgrade">升级成为企业</div>
                <div class="user-id">ID:{{ account_rowid }}</div>
            </dd>
        </dl>
        <ul class="content-table">
            <li class="item">
                <p class="line-overflow">手机</p>
                <p class="line-overflow">{{ mobile }}</p>
                <p class="line-overflow pointer" @click="handleMobile">更改</p>
            </li>
            <li class="item">
                <p class="line-overflow">微信</p>
                <p class="line-overflow">{{ is_wechat_binded ? '已绑定' : '未绑定' }}</p>
                <p class="line-overflow pointer" @click="handleWechat">{{ is_wechat_binded ? '解除绑定' : '绑定' }}</p>
            </li>
            <li class="item">
                <p class="line-overflow">密码</p>
                <p class="line-overflow">{{ hasPwd ? '已设置' : '未设置' }}</p>
                <p class="line-overflow pointer" @click="handlePwd">更改</p>
            </li>
            <li class="item">
                <p class="line-overflow">我的行业</p>
                <p class="line-overflow">
                    <span v-if="business.text">
                        {{ business.text._ }}
                    </span>
                </p>
                <p class="line-overflow pointer" @click="handleBiz">设置</p>
            </li>
        </ul>
        <mobile-modal
            :show="mobileModalShow"
            :mobile="origin_mobile"
            :mobileUnvisible="mobile"
            :code="areaCode"
            @cancel="handleMobileModalCancel"
            @confirm="handleMobileModalSubmit"
        ></mobile-modal>
        <password-modal
            :show="pwdModalShow"
            :mobile="origin_mobile"
            :areaCode="areaCode"
            @cancel="handlePwdModalCancel"
            @confirm="handlePwdModalSubmit"
        ></password-modal>
        <business-modal
            :show="bizModalShow"
            :business="business"
            @cancel="handleBizModalCancel"
            @confirm="handleBizModalSubmit"
        ></business-modal>
        <upgrade-modal
            :show="upgradeModalShow"
            @cancel="handleUpgradeCancel"
            @confirm="handleUpgrageConfirm"
        ></upgrade-modal>
        <wechat-modal
            :show="wechatModalShow"
            @confirm="getData"
            @cancel="hideWechatModal"
        ></wechat-modal>
    </div>  
</template>
<script>
    import { rename } from '@/mixins/index'
    import util from '@/libs/util'
    import mobileModal from './components/mobileModal'
    import passwordModal from './components/passwordModal'
    import businessModal from './components/businessModal'
    import upgradeModal from '@/views/components/upgradeModal'
    import wechatModal from './components/wechatModal'

    export default {
        name: "",
        mixins: [rename],
        components: {
            mobileModal,
            passwordModal,
            businessModal,
            upgradeModal,
            wechatModal
        },
        props: [],
        data () {
            return {
                mobileModalShow: false,
                pwdModalShow: false,
                bizModalShow:false,
                formats: '.jpg,.jpeg,.png',
                avatar_uri: '',
                user_name: '',
                account_rowid: '',
                mobile: '',
                business: {},
                origin_mobile: '',
                upgradeModalShow: false,
                is_wechat_binded: false,
                wechatModalShow: false,
                hasPwd: 0,
                areaCode: ''
            }
        },
        computed: {
            is_org() {
                return this.$store.state.default_bucket_tokens.org
            }
        },
        methods: {
            handleBeforeUpload(file) {
                let name = file.name,
                    reg = /^.*(\..*)$/,
                    format = name.match(reg)[1];

                //处理windows系统无法过滤类型
                if (this.formats.indexOf(format) === -1) {
                    this.$Message.error('不支持的格式')
                    return false
                }
                this.handleUpload(file)
                return false
            },
            handleUpload(file) {
                let formData = new FormData()
                formData.append(file.name, file)
                this.$api.put('me/account/avatar', formData).then(() => {
                    this.$Message.success('成功')
                    this.getData()
                    this.$store.dispatch('GET_BUCKET_TOKENS')
                })
            },
            handleRename(name) {
                this.$api.put('me/account/user_name', { user_name: name }).then(() => {
                    this.$Message.success('成功')
                    this.$store.commit('SET_USERNAME', name)
                })
            },
            handleMobile() {
                this.mobileModalShow = true
            },
            handleMobileModalCancel() {
                this.mobileModalShow = false
            },
            handleMobileModalSubmit() {
                this.handleMobileModalCancel()
                this.getData()
            },
            handlePwd() {
                this.pwdModalShow = true
            },
            handlePwdModalCancel() {
                this.pwdModalShow = false
                this.getData()
            },
            handlePwdModalSubmit() {
                this.handlePwdModalCancel()
                this.getData()
            },
            handleBiz() {
                this.bizModalShow = true
            },
            handleBizModalCancel() {
                this.bizModalShow = false
            },
            handleBizModalSubmit() {
                this.handleBizModalCancel()
                this.getData()
            },
            getData() {
                this.$api.get('me/account').then(({ data }) => {
                    let { avatar_uri, user_name, rowid, pure_mobile, business } = data
                    this.avatar_uri = `${avatar_uri}?${util.randomWord(20)}`
                    this.user_name = user_name
                    this.account_rowid = rowid
                    this.mobile = pure_mobile.toString().replace(/^(\d{3}).*(\d{4})$/, "$1****$2")
                    this.business = business
                    this.origin_mobile = pure_mobile.toString()
                    this.is_wechat_binded = data.is_wechat_binded
                    this.hasPwd = data.password
                    this.areaCode = data.area_code
                    this.pureMobile = data.pure_mobile
                })
            },
            handleUpgrade() {
                this.upgradeModalShow = true
            },
            handleUpgradeCancel() {
                this.upgradeModalShow = false
            },
            handleUpgrageConfirm() {},
            handleWechat() {
                if (this.is_wechat_binded) {
                    this.$Modal.confirm({
                        title: '解除微信绑定',
                        content: '解绑后将无法使用该微信号登录此账号，请谨慎操作！',
                        okText: '确认解绑',
                        cancelText: '暂不解绑',
                        loading: true,
                        closable: true,
                        onOk: () => {
                            this.$api.delete('me/account/wechat').then(() => {
                                this.getData()
                            }).finally(() => {
                                this.$Modal.remove()
                            })
                        }
                    })
                } else {
                    this.wechatModalShow = true
                }
            },
            hideWechatModal() {
                this.wechatModalShow = false
            }
        },
        mounted() {
            this.getData()
        }
    }
</script>
<style scoped lang="less">
@import '~less/color';

#account {
    padding-top: 70px;
    .user-header {
        height: 60px;
        .avatar-wrap {
            position: relative;
            display: inline-block;
            margin-right: 15px;
            width: 60px;
            height: 60px;
            border-radius: 50% 50%;
            vertical-align: top;
            .upload {
                width: 100%;
                height: 100%;
                border-radius: 50% 50%;
                color: #fff;
                div {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 60px;
                    height: 60px;
                }
                .avatar {
                    opacity: 1;
                    display: block;
                    width: 60px;
                    height: 60px;
                    border-radius: 50% 50%;
                    transition: opacity .3s ease;
                }
                &:hover {
                    .avatar {
                        opacity: 0;
                        transition: opacity .3s ease;
                    }
                }
            }
            &:hover {
                .upload {
                    background: #5a6875;
                }
            }
        }
        .info-wrap {
            display: inline-block;
            .name-wrap {
                display: inline-block;
                .name {
                    display: inline-block;
                    position: relative;
                    vertical-align: middle;
                    font-size: 22px;
                    line-height: 32px;
                }
                .icon {
                    vertical-align: middle;
                }
            }
            .upgrade-info {
                display: inline-block;
                margin-left: 28px;
                text-decoration: underline;
                color: @primary-color;
                vertical-align: middle;
            }
        }
    }
    .content-table {
        margin-top: 47px;
        padding: 0 45px;
        background: #fff;
        .item {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-around;
            align-items: center;
            border-bottom: 1px solid #e6e9ee;
            height: 120px;
            font-size: 18px;
            &:last-child {
                border-bottom: none;
            }
            p {
                display: inline-block;
                width: 150px;
                &:first-child {
                    font-weight: 700;
                }
            }
        }
    }
}
</style>