<template>
    <Dropdown class="area-code" transfer placement="bottom-start">
        <div class="code">
            <span class="text">{{ code }}</span>
            <Icon class="icon" type="md-arrow-dropdown" />
        </div>
        <template #list>
            <Input
                class="query"
                v-model="query"
                placeholder="搜索您的国家和地区"
                clearable
                @on-clear="clear"
                @on-change="search">
                    <Icon class="pointer" type="ios-search" slot="suffix"/>
                </Input>
            <DropdownMenu>
                <template v-if="showSearchList">
                     <DropdownItem
                        v-for="item in filterList"
                        :class="['item', item.area_code===code?'selected':'']"
                        :key="item.key"
                        @click.native="handleCurrent(item.area_code)">
                        <span>{{ item.text._ }}</span>
                        <span>{{ item.area_code }}</span>
                    </DropdownItem>
                </template>
                <template v-else>
                    <DropdownItem
                        v-for="item in areas"
                        :class="['item', item.area_code===code?'selected':'']"
                        :key="item.key"
                        @click.native="handleCurrent(item.area_code)">
                        <span>{{ item.text._ }}</span>
                        <span>{{ item.area_code }}</span>
                    </DropdownItem>
                </template>
            </DropdownMenu>
        </template>
    </Dropdown> 
</template>
<script>
    export default {
        name: "",
        mixins: [],
        components: {},
        model: {
            prop: 'code',
            event: 'change',
        },
        props: {
            areas: {
                default: () => []
            },
            code: {
                type: String
            }
        },
        data () {
            return {
                // area_code: '',
                query: '',
                filterList: [],
                showSearchList: false
            }
        },
        computed: {},
        methods: {
            handleCurrent(val) {
                // this.area_code = val
                this.$emit('change', val)
            },
            search() {
                if (this.query === '') {
                    this.filterList = []
                    this.showSearchList = false
                } else {
                    this.filterList = this.areas.filter((item) => item.text._.indexOf(this.query) > -1 || item.area_code.indexOf(this.query) > -1)
                    this.showSearchList = true
                }
            },
            clear() {
                this.query = ''
                this.showSearchList = false
            }
        },
        created() {}
    }
</script>
<style scoped lang="less">
    .code {
        font-size: 16px;
        color: #000;
        .icon, .text {
            vertical-align: middle;
        }
    }
    .query {
        margin: 0 5%;
        width: 90%;
    }
    .item {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .selected {
        font-weight: bold;
    }
</style>