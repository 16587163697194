<template>
    <Modal
        class="wrap"
        v-model="modal"
        title="请选择你的行业"
        :mask-closable="false"
        :width="387"
    >
        <Form ref="formValidate" :model="formValidate" :rules="ruleValidate">
            <FormItem label="" prop="business">
                <Select v-model="formValidate.business" size="large" placeholder="请选择" transfer>
                    <Option v-for="(item, index) in list" :key="index" :value="item.rowid">{{ item.text._ }}</Option>
                </Select>
            </FormItem>
        </Form>
        <div slot="footer" style="margin-right: 15%;">
            <Button type="primary" :loading="loading" @click="submit">{{ $t('common.confirm') }}</Button>
        </div>
    </Modal> 
</template>
<script>
    export default {
        name: "",
        mixins: [],
        components: {},
        props: {
            show: {
                default: false
            },
            business: {
                default: () => {
                    return {}
                }
            }
        },
        data () {
            return {
                modal: false,
                loading: false,
                formValidate: {
                    business: ''
                },
                ruleValidate: {
                    business: [
                        { required: true, type: 'number', message: '请选择', trigger: 'blur' }
                    ]
                },
                list: []
            }
        },
        computed: {},
        watch: {
            show(val) {
                this.modal = val
                if (this.business.rowid) {
                    this.formValidate.business = this.business.rowid
                }
            },
            modal(val) {
                if (!val) {
                    this.cancel()
                }
            }
        },
        methods: {
            cancel() {
                this.$emit('cancel')
                this.formValidate.business = ''
                this.$refs['formValidate'].resetFields();
            },
            submit() {
                this.$refs['formValidate'].validate((valid) => {
                    if (valid) {
                        this.loading = true
                        this.$api.put('me/account/business', { business_rowid: this.formValidate.business }).then(() => {
                            this.$emit('confirm')
                        }).finally(() => {
                            this.loading = false
                        })
                    }
                })
            }
        },
        mounted() {
            this.$api.get('specifications/business/list').then(({ data }) => {
                this.list = data
            })
        }
    }
</script>
<style scoped>
    
</style>