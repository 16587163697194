<template>
    <Modal
        class="wrap"
        v-model="modal"
        title="开通企业"
        :mask-closable="false"
        :closable="false"
        width="387"
    >
        <Form ref="formValidate" :model="formValidate" :rules="ruleValidate" :label-width="80">
            <FormItem label="企业名" prop="name">
                <Input v-model="formValidate.name" placeholder="请输入企业名称"></Input>
            </FormItem>
            <FormItem label="行业" prop="business_id">
                <Select v-model="formValidate.business_id" size="large" placeholder="请选择" transfer>
                    <Option v-for="(item, index) in list" :key="index" :value="item.rowid">{{ item.text._ }}</Option>
                </Select>
            </FormItem>
        </Form>
        <div slot="footer" style="margin-right: 15%;">
            <Button type="default" @click="cancel">{{$t('common.cancel')}}</Button>
            <Button type="primary" :loading="loading" @click="submit">{{$t('common.confirm')}}</Button>
        </div>
    </Modal> 
</template>
<script>
    export default {
        name: "",
        mixins: [],
        components: {},
        props: {
            show: {
                default: false
            },
            type: {
                default: 'add'
            },
            apply: {
                default: () => {
                    return {
                        organization_name: ''
                    }
                }
            }
        },
        data () {
            return {
                modal: false,
                loading: false,
                formValidate: {
                    name: '',
                    business_id: ''
                },
                ruleValidate: {
                    name: [
                        { required: true, message: '请输入企业名称', trigger: 'blur' },
                        { type: 'string', max: 32, message: '最多32个字符', trigger: 'blur' }
                    ],
                    business_id: [
                        { required: true, type: 'number', message: '请选择', trigger: 'blur' }
                    ]
                },
                list: []
            }
        },
        computed: {},
        watch: {
            show(val) {
                this.modal = val
                if (this.type === 'edit') {
                    this.formValidate.name = this.apply.organization_name
                }
            }
        },
        methods: {
            cancel() {
                this.formValidate = {
                    name: '',
                    business_id: ''
                }

                this.$refs['formValidate'].resetFields();
                this.$emit('cancel')
            },
            submit() {
                this.$refs['formValidate'].validate((valid) => {
                    if (valid) {
                        this.loading = true
                        if (this.type === 'add') {
                            this.$api.post('organizations', this.formValidate).then(() => {
                                this.$Message.success('Success!');
                                this.$emit('confirm')
                                this.cancel()
                            }).catch(() => {}).finally(() => {
                                this.loading = false
                            })
                        } else if (this.type === 'edit') {
                            //
                            this.loading = false
                        }
                    }
                })
            }
        },
        created() {},
        mounted() {
            this.$api.get('specifications/business/list').then(({ data }) => {
                this.list = data
            })
        }
    }
</script>
<style scoped>
    
</style>